<template>
  <div :class="['title', {title__collapsed: this.collapsed}]">
    <span>Данные ремонта</span>
    {{ `${this.data?.client_request_id || ''}${this.data?.remont_id ? `/${this.data?.remont_id}` : ''}` }}
  </div>
  <CollapseBlock className="header" :onChange="this.toggleCollapse">
    <div class="header__column">
      <BlockItem
        title="Дата начала ремонта"
        >{{ this.data?.remont_date_begin }}</BlockItem
      >
      <BlockItem
        title="Основной договор"
        >{{ this.numberWithCommas(this.data?.work_agreement_sum) }}</BlockItem
      >
      <BlockItem
        title="Сумма доп. соглашения"
        >{{ this.numberWithCommas(this.data?.ds_agreement_sum) }}</BlockItem
      >
      <BlockItem
        title="Общая сумма"
        >{{ this.numberWithCommas(this.data?.total_agreement_sum) }}</BlockItem
      >
      <BlockItem title="Пакет">{{ this.data?.preset_name}}</BlockItem>
    </div>
    <div class="header__column">
      <BlockItem title="Город">{{ this.data?.city_name }}</BlockItem>
      <BlockItem title="ЖК">{{ this.data?.resident_name }}</BlockItem>
      <BlockItem title="Квартира">{{ this.data?.flat_num }}</BlockItem>
      <BlockItem title="Площадь">{{ `${this.data?.area}м²` }}</BlockItem>
      <BlockItem title="Кол-во комнат">{{ this.data?.room_cnt}}</BlockItem>
    </div>
    <div class="header__column">
      <BlockItem title="Статус">{{ this.data?.status_name }}</BlockItem>
      <BlockItem title="Сообщение">{{ this.data?.message || '-' }}</BlockItem>
      <BlockItem title="Документы">
        <CustomDropdown
          :onClick="this.handleClickDoc"
          :options="this.data?.reports"
          title="Выгрузка"
        />
      </BlockItem>
      <BlockItem
        :pointer="true"
        :onClick="handleCopyLinkClick"
        title="Ссылка на подписание"
      >
        <span v-if="this.data?.client_sign_url">
          Скопировать
          <q-icon size="1.2em" color="primary" name="content_copy"
        /></span>
        <span v-else>-</span>
      </BlockItem>
    </div>
    <div class="header__column">
      <a
        v-if="this.data?.crm_deal_id"
        :href="`https://crm.smartremont.kz/deal/${this.data?.crm_deal_id}`"
        target="_blank"
      >
        <q-btn padding="xs" color="deep-orange" size="sm"
          >Перейти на сделку
        </q-btn>
      </a>
      <BlockItem title="Ссылка на карту(2ГИС)"
        ><a
          v-if="this.data?.gis_link"
          class="link"
          target="_blank"
          :href="this.data?.gis_link"
          >Перейти</a
        >
        <span v-else>-</span>
      </BlockItem>
      <BlockItem title="Проверено аудитором">
        <span v-if="this.data?.is_auditor_checked">
          Проверено
          <q-icon size="1.4em" color="green" name="check" />
        </span>
        <span v-else> - </span>
      </BlockItem>
      <BlockItem title="Грейд">
        <span v-if="this.data?.grade_name">
          {{ this.data?.grade_name }}
          <CustomIcon
            @click="this.handleChangeGrade"
            :small="true"
            icon="edit"
            v-if="this.userData?.permissions.show_update_grade_button"
          />
        </span>
        <span v-else> - </span>
      </BlockItem>
      <BlockItem title="Проект утвержден:" :isRowMode="true">
        <CheckOrClose :value="!!this.data?.project_accepted" />
      </BlockItem>
    </div>
    <div class="header__column">
      <q-btn
        @click="this.onAddTimeline"
        v-if="this.userData?.permissions?.show_add_timeline_button"
        :loading="this.loadingTimeline"
        padding="xs"
        color="primary"
        size="sm"
        style="max-width: 150px;"
      >
        Создать Timeline
      </q-btn>
      <BlockItem v-if="this.data.is_master_payment === 2 || this.data.is_master_payment === null">
        Нет ремонта
      </BlockItem>
      <BlockItem v-else>
        Автоматическая оплата мастерам:  
        <q-toggle
          v-model="this.data.is_master_payment"
          :true-value="1"
          :false-value="0"
          checked-icon="check"
          unchecked-icon="clear"  
          color="green"
          @update:model-value="handleToggleChange" />
      </BlockItem>
    </div>
  </CollapseBlock>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomDropdown from "@/components/common/CustomDropdown";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {addTimelineToRemont, changeRemontGrade, getHeaderData, changeIsMasterPayment } from "@/views/ClientRequests/services";
import {useRoute} from "vue-router/dist/vue-router";
import {FILE_URL, MODAL_NAMES} from "@/constants";
import {numberWithCommas} from "@/services";
import CollapseBlock from "@/components/common/CollapseBlock";
import CustomIcon from "@/components/common/CustomIcon";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CheckOrClose from "@/components/common/CheckOrClose.vue";

export default {
  name: "header",
  props: ['onToggleCollapse', 'setData'],
  components: {CustomIcon, CollapseBlock, CustomDropdown, BlockItem, CheckOrClose},
  data() {
    return {
      data: {},
      collapsed: false,
      loadingTimeline: false,
      previousValue: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
  },
  methods: {
    numberWithCommas,
    handleClickDoc(doc) {
      const url = `${FILE_URL}${doc.report_url}`
      window.open(url, "_blank")
    },
    handleChangeGrade() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Change_Remont_Grade, {
        title: 'Изменить грейд',
        body: {grade_id: this.data?.grade_id}, onSubmit: this.onChangeGrade
      }));
    },
    async onChangeGrade(body) {
      const res = await changeRemontGrade(this.client_request_id, body)
      if (!res) return
      this.data = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onAddTimeline() {
      this.loadingTimeline = true
      const res = await addTimelineToRemont(this.client_request_id)
      this.loadingTimeline = false
      if (!res) return
      this.data = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    handleCopyLinkClick() {
      navigator.clipboard.writeText(this.data?.client_sign_url)
          .then(() => {
            this.showSucceedSnackbar('Ссылка скопирована');
          })
          .catch(() => {
          });
    },
    async getData() {
      const res = await getHeaderData(this.client_request_id)
      if (!res) return
      this.previousValue = res.is_master_payment;
      this.data = res
      this.setData(res)
    },
    toggleCollapse(value) {
      if(this.onToggleCollapse)
        this.onToggleCollapse()
      this.collapsed = value
    },
    async handleToggleChange(newValue) {      
      const res = await changeIsMasterPayment(this.client_request_id, newValue);
      if (!res) {
        // Если ответа от API нет или он невалидный, возвращаем переключатель к предыдущему значению
        this.data.is_master_payment = this.previousValue;
        this.showErrorSnackbar('Что-то пошо не так, признак не изменен!')
        return;
      }
      this.data = res;
      this.showSucceedSnackbar('Успешно изменено!');
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const route = useRoute()
    const {showSucceedSnackbar, showErrorSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar, showErrorSnackbar, client_request_id: +route?.params?.client_request_id};
  },
}
</script>

<style scoped lang="scss">
.header {
  border-radius: 15px;
  border: 2px solid #E4EAF0;
  background: #FFF;
  padding: 25px 20px 40px 20px;
  margin-top: 25px;
  display: flex;
  gap: 35px;
  position: relative;
  transition: all .3s;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}

.title {
  color: #272C39;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  transition: all .3s;

  span {
    font-size: 24px;
    font-weight: 400;
  }

  &__collapsed {
    font-size: 25px;

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

  @media (max-width: 576px) {
    font-size: 25px;
    span {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.link {
  text-decoration: underline;
}
</style>
