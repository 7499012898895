<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect :options="this.materialOptions" label="Материал" :required="true" optionValueKey="material_id"
          optionLabelKey="material_name" :value="this.formData.material_id" :noOptionText="this.noOptionText"
          @change="(value) => onChange('material_id', value)"/>
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getActiveMaterialsList, getReplacementMaterialsList } from "@/views/ProviderRequests/services";

export default {
  name: "provider-requests-material-select",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        material_id: '',
        provider_request_item_id: this.data?.provider_request_item_id,
        client_request_id: this.data?.clientRequestId,
      },
      materialOptions: this.data?.materialOptions || [],
      noOptionText: 'Нет доступных материалов',
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchMaterials() {
      if (this.materialOptions.length === 0) {
        this.noOptionText = 'Загрузка...';
        let res;
        if(this.data.isZamena === true){
          res = await getReplacementMaterialsList(this.data.provider_request_item_id);
        } else {
          res = await getActiveMaterialsList();
        }
        if (!res) {
          this.noOptionText = `Нет доступных материалов`;
          return;
        }
        this.materialOptions = res;
      }
    },
  },
  mounted() {
    this.fetchMaterials();
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  min-width: 800px;

  @media screen and (max-width: 991px) {
    min-width: 100%;
  }
}
// TODO: Реализовать фиксированный размер, который адаптируется при слишком маленьком/большом содержимом
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;

  .form__item {
    overflow: hidden;
    max-width: 100%;
  }
}
</style>