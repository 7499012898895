<template>
    <CollapseBlock>
        <form @submit.prevent="this.onSearch">
        <div class="filters">
            <div class="filters__item">
                <CustomInput type="text" label="ФИО клиента" :value="this.values.search"
                    @clear_input="onChange('search', '')" @change_input="(value) => onChange('search', value)" />
            </div>
            <div class="filters__item">
                <CustomInput type="number" label="Договор" :value="this.values.prop_number"
                    @clear_input="onChange('prop_number', '')"
                    @change_input="(value) => onChange('prop_number', value)" />
            </div>
            <div class="filters__item">
                <CustomSelect :options="this.contractors" label="Подрядчик" optionValueKey="contractor_id"
                    optionLabelKey="contractor_name" :value="this.values.contractor_id"
                    @change="(value) => onChange('contractor_id', value)" />
            </div>
            <div class="filters__item">
                <CustomInput type="number" label="ID ремонта" :value="this.values.remont_id"
                    @clear_input="onChange('remont_id', '')" @change="(value) => onChange('remont_id', value)" />
            </div>
            <FiltersWrapper>
                <CustomDate placeholder="Дата гарантии с" :value="this.values.warranty_date_begin || ''"
                    @change_date="(value) => onChange('warranty_date_begin', value)" />
            </FiltersWrapper>
            <FiltersWrapper>
                <CustomDate placeholder="Дата гарантии по" :value="this.values.warranty_date_end || ''"
                    @change_date="(value) => onChange('warranty_date_end', value)" />
            </FiltersWrapper>
            <FiltersWrapper>
                <CustomDate placeholder="Дата 1c с" :value="this.values.avr_date_begin || ''"
                    @change_date="(value) => onChange('avr_date_begin', value)" />
            </FiltersWrapper>
            <FiltersWrapper>
                <CustomDate placeholder="Дата 1c до" :value="this.values.avr_date_end || ''"
                    @change_date="(value) => onChange('avr_date_end', value)" />
            </FiltersWrapper>

            <div class="filters__item">
                <CustomSelect :options="this.clientDataList" label="Данные клиента" optionValueKey="value"
                    optionLabelKey="label" :value="this.values.is_accept_client_info"
                    @change="(value) => onChange('is_accept_client_info', value)" :clearable="false" />
            </div>

            <div class="filters__item">
                <CustomSelect :options="this.remontExpenses" label="Расходы ремонта" optionValueKey="value"
                    optionLabelKey="label" :value="this.values.is_accept_expense"
                    @change="(value) => onChange('is_accept_expense', value)" :clearable="false" />
            </div>
            
            <div class="filters__item">
                <CustomSelect :options="this.remontTypes" label="Вид ремонта в 1С" optionValueKey="value"
                    optionLabelKey="label" :value="this.values.remont_type_1c"
                    @change="(value) => onChange('remont_type_1c', value)" :clearable="false" />
            </div>
            <div class="filters__item" style="width: 235px;">
                <CustomSelect :options="this.oneCStatuses" label="Отправлен в 1С" optionValueKey="value"
                    optionLabelKey="label" :value="this.values.is_send_to_1c"
                    @change="(value) => onChange('is_send_to_1c', value)" :clearable="false" />
            </div> 
            <div class="filters__item">
                <CustomSelect :options="this.oneCAcceptedList" label="Проведен в 1С" optionValueKey="value"
                    optionLabelKey="label" :value="this.values.is_accept_1c"
                    @change="(value) => onChange('is_accept_1c', value)" :clearable="false" />
            </div>
            <q-btn type="submit" :loading="this.isFetching" size="sm" no-wrap color="primary">
                <q-icon name="search" />
                Поиск
            </q-btn>
        </div>
    </form>


 
    </CollapseBlock>
</template>

<script>
import { clientDataList, remontExpenses, remontTypes, oneCStatuses, oneCAcceptedList } from "./services";
import CollapseBlock from "@/components/common/CollapseBlock";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import FiltersWrapper from "@/components/common/FiltersWrapper";
import { getCommonContractors } from "@/views/Costs/СostsFilters/services";
export default {
    name: "RemontRealizationsFilters",
    components: { CustomInput, FiltersWrapper, CustomSelect, CollapseBlock, CustomDate },
    props: ['values', 'onChange', 'isFetching', 'getData'],
    data() {
        return {
            contractors: [],
            clientDataList,
            remontExpenses,
            remontTypes,
            oneCStatuses,
            oneCAcceptedList
        }
    },
    methods: {
        onChange(type, value) {
            this.$emit('filter_change', type, value);            
        },
        onSearch() {
            this.getData()
        },
        async fetchFiltersMetaData() {
            const res = await getCommonContractors();
            if (!res) return;
            this.contractors = res;
        },
    },

    async mounted() {
        await this.fetchFiltersMetaData();
    },
}
</script>

<style scoped lang="scss">
.filters {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    gap: 15px;
    flex-wrap: wrap;

    &__item {
        width: 220px;
    }
}
</style>