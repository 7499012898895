<template>
  <div class="form">
    <div class="form__item">
      <CustomSelect :options="materials" label="Материал" optionValueKey="material_id"
        optionLabelKey="material_name" :value="formData.material_id"
        @change="(value) => onChange('material_id', value)" />
    </div>
    
    <div class="form__item">
      <CustomSelect :options="providers" label="Поставщик" optionValueKey="provider_id"
        optionLabelKey="provider_name" :value="formData.provider_id"
        @change="(value) => onChange('provider_id', value)" />
    </div>

    <!-- TODO <?if($this->fulfillment_code == 'FULFILLMENT'){?> или если есть row_fulfillment при редактировании -->
    <div class="form__item">
      <CustomSelect :options="fulfillmentOptions" label="Fulfillment" optionValueKey="fulfillment_id"
        optionLabelKey="fulfillment_name" :value="formData.fulfillment_id"
        @change="(value) => onChange('fulfillment_id', value)" />
    </div>
    <!--  -->

    <div class="form__item">
      <CustomInput label="Артикул" :value="formData.vendor_code" @change_input="(value) => onChange('vendor_code', value)" />
    </div>

    <div class="form__item">
      <CustomSelect label="Наличие материала" :value="formData.is_avail"
        @change_input="(value) => onChange('is_avail', value)" />
    </div>

    <div class="form__item">
      <CustomCheckbox label="Создавать потребность" :value="formData.is_required_create"
        @change="(value) => onChange('is_required_create', value)" />
    </div>

    <span>

      <!-- TODO если добавление материала if material_provider_id == 0 -->
      <div class="form__item">
        <CustomInput label="Цена (розничная)" :value="formData.price" type="number"
        @change_input="(value) => onChange('price', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Цена (оптовая)" :value="formData.price_whole" type="number"
        @change_input="(value) => onChange('price_whole', value)" />
      </div>
      
      <div class="form__item">
        <CustomDate label="Дата с" :value="formData.date_begin" @change="(value) => onChange('date_begin', value)" />
        </div>
        
        <div class="form__item">
          <CustomDate label="Дата по" :value="formData.date_end" @change="(value) => onChange('date_end', value)" />
          </div>
          
          <!-- <?if($this->fulfillment_code == 'FULFILLMENT'){?>
                <div class="form-group hidden">
                    <div class="checkbox">
                        <label class="block fit-content-width">
                            <input name="is_insert_sr" type="hidden" value="0">
                            <input name="is_insert_sr" type="checkbox" class="ace input-lg">
                            <span class="lbl bigger-120 user-select-none"> Обновить цену для SR</span>
                        </label>
                    </div>
                </div>
                <? -->
        </span>
          <div class="form__item">
            <CustomInput label="Наименование материала у поставщика" :value="formData.provider_material_name"
            @change_input="(value) => onChange('provider_material_name', value)" />
          </div>
    
    <div class="form__item">
      <CustomInput label="Количество дней на выполнение услуги" :value="formData.overdue_day_cnt"
        type="number" @change_input="(value) => onChange('overdue_day_cnt', value)" />
    </div>

    <div class="form__item">
      <CustomCheckbox label="Требуется вызов на второй этап" :value="formData.is_invite_second"
        @change="(value) => onChange('is_invite_second', value)" />
    </div>
  </div>

  <div class="footer">
    <q-btn class="footer__btn footer__btn_cancel" label="Назад" color="red" icon="close" @click="onClose"
      :disable="submitLoading">
    </q-btn>
    <q-btn class="footer__btn footer__btn_submit" label="Сохранить" color="secondary" icon="check"
      :disable="submitLoading" :loading="submitLoading" @click="onSubmitSave">
    </q-btn>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: 'MaterialAddChange',
  components: {
    CustomSelect,
    CustomDate,
    CustomInput,
    CustomCheckbox,
  },
  data() {
    return {
      submitLoading: false,
      formData: {
        material_id: '',
        provider_id: '',
        fulfillment_id: '',
        vendor_code: '',
        is_avail: '',
        is_required_create: false,
        price: '',
        price_whole: '',
        date_begin: '',
        date_end: '',
        provider_material_name: '',
        overdue_day_cnt: '',
        is_invite_second: false,
      },
      materials: [],
      fulfillmentOptions: [], 
      providers: [],
    };
  },
  methods: {
    onClose() {
      this.submitLoading = false;
      this.$emit('close');
    },
    onSubmitSave() {
      this.submitLoading = true;
      console.log('submit');
      this.submitLoading = false;
    },
    onChange(field, value) {
      this.formData[field] = value;
    }
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1020px;
  gap: 15px;
  margin-bottom: 35px;

  &__item {
    max-width: 320px;
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
.footer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  &__btn {
    &.footer__btn_cancel {
      background-color: #f5f5f5;
      color: #e74c3c;
    }

    &.footer__btn_submit {
      background-color: #3498db;
      color: #fff;
    }

    &.footer__btn_disabled {
      opacity: 0.5;
    }
  }
}
</style>