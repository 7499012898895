export const getFiltersMetaData = async () => {
  try {
    console.log('getFiltersMetaData');
    return
    const res = await providerRequestsFiltersAPI.getMaterials();
    return res?.data;
  } catch (e) {}
};
export const getMaterials = async () => {
  try {
    const res = await providerRequestsFiltersAPI.getMaterials();
    return res?.data;
  } catch (e) {}
};

const shippedFromWarehouse = [
  { label: "Все", value: 0 },
  { label: "Да", value: 1 },
  { label: "Нет", value: 2 },
];
const materialActivity = [
  { label: "Активный", value: 1 },
  { label: "Неактивный", value: 2 },
];
const availabilityOptions = [
  { label: "Все", value: 0 },
  { label: "В наличии", value: 1 },
  { label: "Нет в наличии", value: 2 },
];