<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect :options="contractorOptions" label="Подрядчик" :required="true" optionValueKey="contractor_id"
          optionLabelKey="contractor_name" :value="selectedContractorValue"
          @change="(value) => onChange('contractor_id_new', value)"  />
      </div>
      
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getProviderRequestContractors } from "@/views/ProviderRequests/services";

export default {
  name: "provider-requests-contractor-form",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        contractor_id: this.data?.body?.contractor_id || '',
        contractor_id_new: '',
        provider_request_id: this.data.body.provider_request_id || ''
      },
      contractorOptions: [],
    }
  },
  computed: {
    selectedContractorValue() {
      const selectedOption = this.contractorOptions.find(
        (option) => option.contractor_id === this.formData.contractor_id_new || option.contractor_id === this.formData.contractor_id
      );
      return selectedOption && selectedOption.contractor_name ? selectedOption.contractor_id : '';
    },
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchContractors() {      
      this.contractorOptions = await getProviderRequestContractors();
    },
  },
  mounted() {
    this.fetchContractors();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  width: min(550px, 90vw); 
  max-width: 100%;
  min-width: 300px; 
  
  .form__item {
    flex: 1 1 45%;
  }
}
</style>