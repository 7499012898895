<template>
  <div class="backgroundBlue">
    <BlockItem>
      <span>Для создания доп.заявки выберите причину. <br>
        При необходимости, можно отфильтровать по типу и виду материала.</span>
    </BlockItem>
  </div>
  <div class="hr"></div>

  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomDate placeholder="План отгрузки с" :value="this.formData.date_shipping_plan || ''"
        @change_date="(value) => onChange('date_shipping_plan', value)" />
    </div>
    <div class="form__item">
      <CustomSelect :options="this.reasons" label="Причина" optionValueKey="provider_request_additional_reason_id"
        optionLabelKey="reason_name" :value="this.formData.reason_id"
        @change="(value) => onChangeAndSearch('reason_id', value)" />
    </div>
    <div class="form__item">
      <CustomSelect :options="this.materialTypes" label="Тип материала" optionValueKey="material_type_id"
        optionLabelKey="material_type_name" :value="this.formData.material_type_id"
        @change="(value) => onChangeAndSearch('material_type_id', value)" />
    </div>

    <div class="form__item">
      <CustomInput type="textarea" label="Комментарии" :value="this.formData.comments"
        @clear_input="onChange('comments', '')" @change_input="(value) => onChange('comments', value)" />
    </div>

    <div class="footer">
      <q-btn class="footer__btn footer__btn_submit" label="Создать заявку" color="secondary" icon="check"
        :disable="submitLoading || searchLoading" :loading="submitLoading" @click="onSubmitSave">
      </q-btn>
      <q-btn class="footer__btn footer__btn_cancel" label="Назад" color="red" icon="close" @click="onClose"
        :disable="submitLoading || searchLoading">
      </q-btn>
      <q-btn class="footer__btn footer__btn_submit" label="Добавить материал" color="primary" icon="add"
        :disable="submitLoading || searchLoading" @click="showModalMaterialSelect">
      </q-btn>
      <q-btn class="footer__btn footer__btn_submit" label="Обновить" color="primary" icon="search"
        :disable="searchLoading" :loading="searchLoading" @click="getClearTableData">
      </q-btn>
    </div>
  </q-form>

  <CustomTable :rowsData="this.tableData" :columns="this.tableColumns" :stickyHeader="false" :loading="searchLoading"
    :custom-body="true" :small="true" :checkbox="true" :checkbox-value="this.allSelected"
    :on-all-check-click="onAllCheckClick">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell v-if="!this.client_request_id">
          <div class="check__wrapper">
            <CustomCheckbox :value="!!this.selectedItems[props.row.provider_request_material_id]"
              @change="(value) => onCheckboxChange(props.row.provider_request_material_id, value)" />
          </div>
        </custom-table-cell>
        <!-- ID -->
        <custom-table-cell align="right">
          <span>
            {{ props.row.material_id }}
          </span><br />
          <span class="text-primary" title="provider_request_item_id">
            {{ props.row.provider_request_item_id }}
          </span><br />
          <span class="text-secondary" title="provider_request_id">
            {{ props.row.provider_request_id }}
          </span>
        </custom-table-cell>

        <!-- Фото -->
        <custom-table-cell align="center">
          <CustomImg class="photo" :src="props.row.photo_url" alt="material_photo" />
        </custom-table-cell>

        <!-- Название материала -->
        <custom-table-cell align="left">
          <span v-html="`${props.row.material_name}`"> </span>
        </custom-table-cell>

        <!-- Дополнительное описание -->
        <custom-table-cell align="left">
          {{ props.row.material_name_extra }}<br />
          <span title="Поставщик">
            {{ props.row.provider_name }}
          </span>
        </custom-table-cell>

        <!-- Статус заявки -->
        <custom-table-cell align="left">
          {{ props.row.provider_request_status_name }}
        </custom-table-cell>

        <!-- Цена -->
        <custom-table-cell align="right">
          {{ props.row.price | currency }}
        </custom-table-cell>

        <custom-table-cell align="center">
          <!-- Количество (CONSUME) -->
          <div class="editable-text position-relative consume-cnt">
            <CustomInput class="custom-Input-Width"
              :value="localChanges[props.row.provider_request_material_id]?.material_cnt || props.row.material_cnt"
              :controls="true"
              :style="`background-color: ${hasAnyChanges(props.row.provider_request_material_id, 'CONSUME') ? '#d0ffc3' : 'unset'};`"
              @change_input="(value) => handleChange('CONSUME', props.row.provider_request_material_id, props.row.material_id, value)"
              @keydown.enter.prevent="saveChanges('CONSUME', value)"
              @blur="resetChanges(props.row.provider_request_material_id, 'CONSUME')" type="number"
              :showClearIcon="false" />
            <q-tooltip>Нажмите <kbd>Enter</kbd> для сохранения изменений</q-tooltip>
          </div>
          {{ props.row.consume_unit_name }}
        </custom-table-cell>

        <custom-table-cell align="center">
          <!-- Количество (ATOM) -->
          <div class="editable-text position-relative atom-cnt">
            <CustomInput class="custom-Input-Width"
              :value="localChanges[props.row.provider_request_material_id]?.cnt_unit || props.row.cnt_unit"
              :controls="true"
              :style="`background-color: ${hasAnyChanges(props.row.provider_request_material_id, 'ATOM') ? '#d0ffc3' : 'unset'};`"
              @change_input="(value) => handleChange('ATOM', props.row.provider_request_material_id, props.row.material_id, value)"
              @keydown.enter.prevent="saveChanges('ATOM', value)"
              @blur="resetChanges(props.row.provider_request_material_id, 'ATOM')" type="number"
              :showClearIcon="false" />
            <q-tooltip>Нажмите <kbd>Enter</kbd> для сохранения изменений</q-tooltip>
          </div>
          {{ props.row.atom_unit_name }}
        </custom-table-cell>


        <!-- Замена материала (если требуется) -->
        <custom-table-cell align="center" v-if="props.row?.is_zamena === 1" class="td-fixed-width-350">
          <Clickable href="javascript:void(0)" @click="showChangeMaterialForm(props.row.provider_request_item_id)"
            style="text-decoration: none;">
            {{ props.row.analog_material_name }}
          </Clickable>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInput from "@/components/form/CustomInput";
import { getMaterialListClearTableData, getMaterialListOnAddTableData, getMaterialListZamena, getMaterialTypes, getReasons, NewReqTableColumns, IsZamenaTableColumns, getActiveMaterialsList, materialCntChangeNewReq, createAdditionalReq } from './services'
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomImg from "@/components/common/CustomImg";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { format } from "date-fns";

export default {
  name: "ProviderCreateNewReq",
  components: {
    CustomSelect,
    CustomModalFooter,
    BlockItem,
    CustomInput,
    CustomTableCell,
    CustomIcon,
    Clickable,
    CustomTable,
    Amount,
    CustomDate,
    CustomImg,
    CustomCheckbox,
  },
  props: {
    clientRequestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localChanges: {},
      submitLoading: false,
      searchLoading: false,
      selectedItems: {},
      allSelected: false,
      formData: {
        date_shipping_plan: format(new Date(), 'dd.MM.yyyy'),
        reason_id: '',
        reason_name: '',
        material_type_id: '',
        material_type_name: '',
        comments: '',
        client_request_id: this.clientRequestId,
      },
      temp_material_id: '',
      temp_provider_request_material_id: '',
      materialTypes: [],
      reasons: [],
      materialOptions: [],
      tableData: [],
      provData: [],
      tableColumns: NewReqTableColumns,
    }
  },
  methods: {
    async getClearTableData() {
      this.searchLoading = true;
      const body = {
        reason_id: this.formData.reason_id,
        material_type_id: this.formData.material_type_id,
        client_request_id: this.clientRequestId,
      }
      const selectedReason = this.reasons.find((reason) => reason.provider_request_additional_reason_id === this.formData.reason_id);

      if (selectedReason?.is_zamena === 1) { this.tableColumns = IsZamenaTableColumns; }
      else { this.tableColumns = NewReqTableColumns; }

      const res = await getMaterialListClearTableData(body);
      this.searchLoading = false;
      if (!res) return;
      this.tableData = res;
      this.materialCntData = res.reduce((acc, row) => {
        acc[row.provider_request_material_id] = {
          material_cnt: row.material_cnt,
          cnt_unit: row.cnt_unit,
        };
        return acc;
      }, {});
    },
    onChange(key, value) {
      this.formData[key] = value;
    },
    onChangeAndSearch(key, value) {
      this.formData[key] = value;
      this.getClearTableData();
    },
    async onSubmitSave() {
      const selectedIds = this.getSelectedProviderRequestMaterialIds();
      if (selectedIds.length === 0) {
        this.showErrorSnackbar('Выберите материал(-ы)');
        return;
      }
      // Для каждого выбранного материала проверяем, что количество не пустое и не равно нулю.
      for (const id of selectedIds) {
        const row = this.tableData.find(item => item.provider_request_material_id === id);
        if (!row) continue;
        const cntUnit = this.localChanges[id]?.cnt_unit || row.cnt_unit;
        const materialCnt = this.localChanges[id]?.material_cnt || row.material_cnt;
        if (!cntUnit || Number(cntUnit) === 0 || !materialCnt || Number(materialCnt) === 0) {
          this.showErrorSnackbar('Измените количество материала');
          return;
        }
      }

      this.submitLoading = true;
      const provider_request_material_id_arr = this.getSelectedProviderRequestMaterialIds();

      const body = {
        provider_request_material_id_arr: provider_request_material_id_arr,
        client_request_id: this.clientRequestId,
        date_shipping_plan: this.formData.date_shipping_plan,
        provider_request_additional_reason_id: this.formData.reason_id,
        comments: this.formData.comments,
      }

      const res = await createAdditionalReq(body);
      this.submitLoading = false;
      if (!res) return;
      this.$emit("close");
    },
    onClose() {
      this.$emit("close");
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.selectedItems = this.tableData.reduce((acc, item) => {
        acc[item.provider_request_material_id] = value;
        return acc;
      }, {});
    },
    onCheckboxChange(provider_request_material_id, value) {
      this.selectedItems = { ...this.selectedItems, [provider_request_material_id]: value };
    },
    getSelectedProviderRequestMaterialIds() {
      return Object.keys(this.selectedItems)
        .filter((id) => this.selectedItems[id])
        .map(Number);
    },
    showModalMaterialSelect() {
      if (!this.materialOptions || this.materialOptions.length === 0) {
        this.showErrorSnackbar("Пожалуйста, дождитесь загрузки списка материалов.");
        return;
      }
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_MATERIAL_SELECT, {
        onSubmit: (body) => this.onUpdateMaterialsListSubmit(body),
        title: 'Добавить материал',
        clientRequestId: this.clientRequestId,
        materialOptions: this.materialOptions,
        isZamena: false,
      }));
    },
    showChangeMaterialForm(provider_request_item_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_MATERIAL_SELECT, {
        onSubmit: (body) => this.onZamenaMaterialsListSubmit(body),
        title: 'Выбрать материал замены',
        clientRequestId: this.clientRequestId,
        provider_request_item_id: provider_request_item_id,
        isZamena: true,
      }));
    },

    async onUpdateMaterialsListSubmit(body) {
      const res = await getMaterialListOnAddTableData(body);
      if (!res) return;
      this.tableData = res;
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onZamenaMaterialsListSubmit(body) {
      const res = await getMaterialListZamena(body);
      if (!res) return;
      this.tableData = res;
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    hasAnyChanges(providerRequestMaterialId, type) {
      const changes = this.localChanges[providerRequestMaterialId];
      if (!changes) return false;

      if (type === 'CONSUME') {
        const originalCnt = this.materialCntData[providerRequestMaterialId]?.material_cnt;
        return changes.material_cnt !== undefined && +changes.material_cnt !== originalCnt;
      } else if (type === 'ATOM') {
        const originalCnt = this.materialCntData[providerRequestMaterialId]?.cnt_unit;
        return changes.cnt_unit !== undefined && +changes.cnt_unit !== originalCnt;
      }
      return false;
    },
    handleChange(type, providerRequestMaterialId, material_id, newValue) {
      if (!providerRequestMaterialId) {
        console.warn('Invalid providerRequestMaterialId:', providerRequestMaterialId);
        return;
      }

      if (!this.localChanges[providerRequestMaterialId]) {
        this.localChanges = {
          ...this.localChanges,
          [providerRequestMaterialId]: {},
        };
      }
      this.temp_material_id = material_id;
      this.temp_provider_request_material_id = providerRequestMaterialId;

      const key = type === 'CONSUME' ? 'material_cnt' : 'cnt_unit';
      this.localChanges[providerRequestMaterialId][key] = newValue;
    },
    async saveChanges(type) {
      const changes = this.localChanges[this.temp_provider_request_material_id];

      if (!changes) return;

      const payload = {
        material_id: this.temp_material_id,
        material_cnt: type === 'CONSUME' ? changes.material_cnt : changes.cnt_unit,
        type: type,
        provider_request_material_id: this.temp_provider_request_material_id,
      };

      try {
        const res = await materialCntChangeNewReq(payload);
        const updatedRow = this.tableData.find(
          (row) => row.provider_request_material_id === this.temp_provider_request_material_id
        );

        if (updatedRow) {
          updatedRow.material_cnt = res.qty_consume;
          updatedRow.cnt_unit = res.qty_atom;
        }
        delete this.localChanges[this.temp_provider_request_material_id];
        this.localChanges = { ...this.localChanges };
        this.showSucceedSnackbar('Изменения успешно сохранены!');
      } catch (error) {
        this.showErrorSnackbar('Ошибка при сохранении изменений');
      }
      this.temp_material_id = '';
      this.temp_provider_request_material_id = '';
    },
    resetChanges(providerRequestMaterialId, type) {
      const changes = this.localChanges[providerRequestMaterialId];
      if (this.hasAnyChanges(providerRequestMaterialId, type)) {
        if (type === 'CONSUME') {
          this.localChanges = {
            ...this.localChanges,
            [providerRequestMaterialId]: {
              ...changes,
              material_cnt: undefined,
            },
          };
        } else if (type === 'ATOM') {
          this.localChanges = {
            ...this.localChanges,
            [providerRequestMaterialId]: {
              ...changes,
              cnt_unit: undefined,
            },
          };
        }
      }
      if (
        !this.localChanges[providerRequestMaterialId]?.material_cnt &&
        !this.localChanges[providerRequestMaterialId]?.cnt_unit
      ) {
        const { [providerRequestMaterialId]: _, ...remainingChanges } = this.localChanges;
        this.localChanges = remainingChanges;
      }
    },
  },
  async mounted() {
    this.materialTypes = await getMaterialTypes();
    this.reasons = await getReasons();
    this.materialOptions = await getActiveMaterialsList();
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar, showErrorSnackbar }
  },
}
</script>

<style scoped lang="scss">
.header {
  border-radius: 15px;
  border: 2px solid #E4EAF0;
  background: #FFF;
  padding: 25px 20px 40px 20px;
  margin-top: 25px;
  display: flex;
  gap: 35px;
  position: relative;
  transition: all .3s;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;

  &:last-child {
    border: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1020px;
  gap: 15px;
  margin-top: 35px;
  margin-bottom: 35px;

  &__item {
    max-width: 320px;

    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;

      &Label {
        margin-bottom: 3px;
        font-size: 13px;
        display: block;
        color: #3b3b3b;
      }
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  &__btn {
    &.footer__btn_cancel {
      background-color: #f5f5f5;
      color: #e74c3c;
    }

    &.footer__btn_submit {
      background-color: #3498db;
      color: #fff;
    }

    &.footer__btn_disabled {
      opacity: 0.5;
    }
  }
}

.backgroundBlue {
  max-width: 550px;
  /* // Голубой */
  background-color: #E3F2FD;
  border-radius: 8px;
  padding: 20px;
}

.photo {
  max-width: 90px;
  max-height: 90px;
}

.custom-Input-Width {
  min-width: 60px !important;
  max-width: 60px !important;
  margin: 0 auto;
}
</style>