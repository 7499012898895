<template>
  <q-linear-progress indeterminate v-if="this.isFetching" />
  <CreateDSTemplate cancelBtnText="Закрыть" :submitBtnText="this.dsData?.ds_id ? 'Сохранить' : 'Создать'"
    :disabled="this.isSubmitDisabled" :cancelApproveMode="this.cancelApproveMode"
    :on-submit="this.dsData?.ds_id ? this.updateDS : this.updateDS" :on-cancel="this.onCancel" :loading="this.loading"
    :label="this.dsData?.ds_type_name">

    <div class="form">
      <div class="form__header">
        <div class="form__header_inputs">
          <div class="form__header_item">
            <CustomDate :full-width="true" :disable-past="true" placeholder="Дата" :value="this.formData.ds_date || ''"
              @change_date="(value) => onChange('ds_date', value)" />
          </div>
          <div class="form__header_item">
            <CustomCheckbox label="Сумма ДС вручную" v-model="this.formData.is_zero_sum" :value="formData.is_zero_sum"
              :true-value="1" :false-value="0" @change="onManualSumChange"
              @change_input="(value) => onChange('is_zero_sum', value)" />
          </div>

          <div class="form__header_item manualDsSum">
            <div class="form__header_item">
              <CustomInput :autoMinWidth="true" label="Сумма ДС" type="number" v-model="this.formData.ds_sum"
                :disable="!manualSum" @change_input="(value) => onChange('ds_sum', value)" />
            </div>
            <div class="form__header_item">
              <CustomInput :required="true" :autoMinWidth="true" label="Комментарий для ДС с ручной стоимостью"
                type="text" v-model="this.formData.zero_sum_comment" :disable="!manualSum"
                @change_input="(value) => onChange('zero_sum_comment', value)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CreateDSTemplate>

  <FetchingWrapper :loading="this.isCustomTabFetching" :data="this.data">
    <div class="customTabs__wrapper" v-show="!!this.formData?.ds_id">
      <CustomTabs :tab="tab" :data="this.data" label-option="grant_name" name-option="component"
        @change_tab="this.onChangeCustomTab">
        <template v-slot:default="item">
          <KeepAlive>
            <component :is="tab" :data="item.data?.data" :ds_id="formData?.ds_id" :client_request_id="client_request_id"
              :textConstructorData="this.textConstructorData" :materialCntData="this.materialCntData"
              :refreshTextConstructor="this.loadTextConstructor" :localChanges="this.localChanges" />
          </KeepAlive>
        </template>
      </CustomTabs>
    </div>
  </FetchingWrapper>





</template>

<script>
import CreateDSTemplate from "@/views/ClientRequests/remontDS/dsCreationTemplates/template";
import { createDS, updateDS, getDSData } from "@/views/ClientRequests/remontDS/services";
import { getTextConstructorDsData } from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services";
import CustomInput from "@/components/form/CustomInput";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomDate from "@/components/form/CustomDate";

import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

import TextConstructorChangeBlock from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/textConstructorChangeBlock";
import MaterialsChangedBlock from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/materialsChangedBlock";

import FetchingWrapper from "@/components/common/FetchingWrapper";
import CustomTabs from "@/components/common/CustomTabs";
import CustomTabPanel from "@/components/common/CustomTabs/CustomTabPanel";





export default {
  name: "ChangeTextConstructorDS",
  components: {
    CustomInput, CustomInputNumber, CustomCheckbox, CustomDate, CreateDSTemplate,
    CustomTabPanel, CustomTabs, FetchingWrapper,
    TextConstructorChangeBlock, MaterialsChangedBlock
  },
  props: ['onCancel', 'updateData', 'client_request_id', 'data', 'dsData', 'isFetching'],
  data() {
    return {
      textConstructorData: [],
      materialCntData: {},
      localChanges: {},
      isFetching: false,
      loading: false,
      isFormChanged: !!this.dsData?.ds_id,
      data: [
        {
          grant_name: "Текстовый конструктор",
          component: "TextConstructorChangeBlock",
          data: {},
        },
        {
          grant_name: "Измененные материалы",
          component: "MaterialsChangedBlock",
          data: {},
        },
      ],
      isCustomTabFetching: false,
      tab: 'TextConstructorChangeBlock' || '',
      formData: {
        ds_id: this.dsData?.ds_id || '',
        ds_type_id: this.dsData?.ds_type_id,
        ds_date: new Date().toLocaleDateString('ru'),
        ds_sum: this.dsData?.ds_sum,
        is_zero_sum: this.dsData?.is_zero_sum || 0,
        zero_sum_comment: this.dsData?.zero_sum_comment,
      },
    };
  },
  computed: {
    isSubmitDisabled() {
      if (!this.savedData) return false
    },
    cancelApproveMode() {
      return !this.isSubmitDisabled && this.isFormChanged
    },
  },
  methods: {
    async createDS(onMount = false) {
      if (this.formData.ds_id) return
      this.loading = true;
      try {
        const body = onMount ? { ds_type_id: this.dsData?.ds_type_id } : this.formData
        const res = await createDS(this.client_request_id, body);

        this.loading = false;
        if (res) {
          this.formData.ds_id = res.header.ds_id;
          if (onMount) {
            this.formData = { ...this.formData, ...res.header, ...res.data }
          } else {
            this.updateData && this.updateData();
            this.showSucceedSnackbar();
          }
        } else {
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async updateDS() {
      this.loading = true;
      const res = await updateDS(this.client_request_id, this.formData.ds_id, this.formData);
      this.loading = false;
      if (res) {
        this.updateData && this.updateData();
        this.showSucceedSnackbar();
      }
    },
    async getDS() {
      this.isFetching = true
      const res = await getDSData(this.client_request_id, this.dsData?.ds_id)
      this.isFetching = false
      if (!res) return
      this.formData = { ...this.formData, ...res.header, ...res.data }
    },
    // Новый метод для загрузки данных текстового конструктора
    async loadTextConstructor() {
      const res = await getTextConstructorDsData(this.client_request_id, this.formData.ds_id);
      if (!res) return;
      const { data, materialCntData } = res;
      this.materialCntData = { ...materialCntData };
      this.localChanges = { ...materialCntData }
      this.textConstructorData = data;
    },
    onChangeCustomTab(tab) {
      this.tab = tab
    },
    onChange(key, value) {
      this.isFormChanged = true
      this.formData[key] = value || null
    },
  },
  async mounted() {
    if (this.dsData?.ds_id) {
      this.getDS();
      await this.loadTextConstructor();  // Load data
    } else {
      await this.createDS(true);
      console.log('createDS');
      await this.loadTextConstructor();
    }
  },
  computed: {
    isTextConstructorLoaded() {
      return this.textConstructorData && this.textConstructorData.length > 0;
    }
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar }
  }
};
</script>


<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: grid;
      grid-template-columns: 3fr 2fr 7fr;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 12fr;
      }
    }

    &_info {
      margin-top: 20px;

      &>p {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    margin-bottom: 10px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 3fr 6fr 3fr;
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 12fr;
    }
  }
}

.manualDsSum {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 3fr 6fr 3fr;
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 3fr 9fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 6fr 6fr;
  }
}

.textConstructor {
  &__header {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__photo {
    max-width: 90px;
    max-height: 90px;
  }

  &__sets {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_item {
      padding: 5px 8px;
    }

    &_title {
      font-weight: 500;
    }

    &_label {
      color: #3c69b2;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__star {
    font-size: 20px;
    color: #69AA46;
    margin-right: 2px;
  }
}

.customTabs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
</style>