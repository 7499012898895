import { textConstructorDsAPI } from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/api";
import { downloadExcel } from "@/services";


export const getTextConstructorDsData = async (client_request_id, ds_id) => {
  try {
    const res = await textConstructorDsAPI.getTextConstructorMaterials(client_request_id, ds_id);
    const data = res?.data;
    if (!data?.length) return;

    const materialCntData = {};
    data.forEach((item, index) => {
      const items = {};
      item.items_json?.forEach((child) => {
        items[child.material_id] = child.material_cnt;
      });
      materialCntData[index] = {  // вместо item.client_material_id используем индекс
        material_cnt: item.material_cnt,
        items,
      };
    });

    return { data, materialCntData };
  } catch (e) {}
};

export const getChangedMaterialsDsData = async (client_request_id, ds_id) => {
  try {
    const res = await textConstructorDsAPI.getChangedMaterials(client_request_id, ds_id);
    const data = res?.data;
    if (!data?.length) return;
    return data;
  } catch (e) {}
};

export const getTKRooms = async (client_request_id) => {
  try {
    const res = await textConstructorDsAPI.getRooms(client_request_id);
    return res?.data;
  } catch (e) {}
};

export const getTKWorkSets = async (client_request_id, room_id) => {
  try {
    const res = await textConstructorDsAPI.getWorkSets(client_request_id, room_id);
    return res?.data;
  } catch (e) {}
};

export const getMaterialPriceData = async (client_request_id, tk_change_id) => {
  try {
    const res = await textConstructorDsAPI.getMaterialPriceData(client_request_id, tk_change_id);
    return res;
  } catch (e) {}
};

export const changeMaterialPriceData = async (body) => {
  try {
    const res = await textConstructorDsAPI.changeMaterialPriceData(
      body.client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
}; 
export const printDsDoc = async (client_request_id, ds_id) => {
  try {
    const res = await textConstructorDsAPI.printDsDoc(client_request_id, ds_id);
    const currentTime = new Date().toLocaleTimeString("ru", { hour: '2-digit', minute: '2-digit', second: '2-digit' }); // Получаем время в формате HH:mm
    downloadExcel(res, `Измененные материалы ДС №${ds_id}`, currentTime)
  } catch (e) {}
};

export const addTKWork = async (body) => {
  try {
    const res = await textConstructorDsAPI.addWork(
      body.client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};

export const getMCMaterials = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.getMaterials(client_request_id, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (e) {}
};

export const getMCRooms = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.getRoomsMC(client_request_id, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (e) {}
};

export const getMCWorkSets = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.getWorkSetsMC(client_request_id, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (e) {}
};

export const getMCMaterialSets = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.getMaterialSet(client_request_id, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (e) {}
};

export const addMCWork = async (body) => {
  try {
    const res = await textConstructorDsAPI.addMaterial(
      body.client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};

export const modifyCancel = async (client_request_id, tk_change_id) => {
  try {
    const res = await textConstructorDsAPI.modifyCancel(
      client_request_id,
      tk_change_id,
    );
    return res?.data;
  } catch (e) {}
};

export const materialDelete = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.deleteMaterial(
      client_request_id,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (e) {}
};

export const deleteCancel = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.deleteCancel(
      client_request_id,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (e) {}
};
 
export const saveMaterialCnt = async (client_request_id, body) => {
  try {
    const res = await textConstructorDsAPI.saveMaterialCount(
      client_request_id,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (e) {}
};



export const textConstructorColumns = [
  {
    name: "client_material_id",
    label: "ID",
    field: "client_material_id",
    align: "center",
  },
  {
    name: "room_name",
    label: "Комната",
    field: "room_name",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "center",
  },
  {
    name: "material_cnt",
    label: "Кол-во",
    field: "material_cnt",
    align: "center",
  },
  {
    name: "unit_name",
    label: "Ед.изм",
    field: "unit_name",
    align: "center",
  },
  {
    name: "material_set_name",
    label: "Набор",
    field: "material_set_name",
    align: "center",
  },
  {
    name: "photo",
    label: "Фото набора/материала",
    field: "photo",
    align: "center",
  },
  {
    name: "actions",
    label: "Действия",
    field: "actions",
    align: "center",
  },
];

export const textConstructorMaterialsColumns = [
  {
    name: "material_id",
    label: "ID",
    field: "material_id",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "photo_url",
    label: "Фото",
    field: "photo_url",
    align: "center",
  },
  {
    name: "material_cnt",
    label: "Кол-во",
    field: "material_cnt",
    align: "right",
  },
  {
    name: "unit_name",
    label: "Ед.изм",
    field: "unit_name",
    align: "left",
  },
];

export const changedMaterialsColumns = [
  {
    name: "tk_change_id",
    label: "ID",
    field: "tk_change_id",
    align: "center",
  },
  {
    name: "room_name",
    label: "Комната",
    field: "room_name",
    align: "center",
  },
  {
    name: "work_set_name_old",
    label: "Старый конструктив",
    field: "work_set_name_old",
    align: "center",
  },
  {
    name: "material_name_old",
    label: "Старый материал",
    field: "material_name_old",
    align: "center",
  },
  {
    name: "material_set_name_old",
    label: "Старый набор",
    field: "material_set_name_old",
    align: "center",
  },
  {
    name: "photo_url_old",
    label: "Фото набора/материала (Старый)",
    field: "photo_url_old",
    align: "center",
  },
  {
    name: "price_old",
    label: "Старая цена",
    field: "price_old",
    align: "center",
  },
  {
    name: "work_set_name_new",
    label: "Замененный конструктив",
    field: "work_set_name_new",
    align: "center",
  },
  {
    name: "material_new_name",
    label: "Замененный материал",
    field: "material_new_name",
    align: "center",
  },
  {
    name: "material_set_name_new",
    label: "Набор",
    field: "material_set_name_new",
    align: "center",
  },
  {
    name: "photo_url_new",
    label: "Фото набора/материала (Новый)",
    field: "photo_url_new",
    align: "center",
  },
  {
    name: "price_new",
    label: "Новая цена",
    field: "price_new",
    align: "center",
  },
  {
    name: "price_diff",
    label: "Разница в цене",
    field: "price_diff",
    align: "center",
  },
  {
    name: "change_date",
    label: "Дата",
    field: "change_date",
    align: "center",
  },
  {
    name: "actions",
    label: "Действия",
    field: "actions",
    align: "center",
  },
];

export const changedMaterialsInnerColumns = [
  {
    name: "material_id",
    label: "ID",
    field: "material_id",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "photo_url",
    label: "Фото",
    field: "photo_url",
    align: "center",
  },
  {
    name: "material_cnt_old",
    label: "Кол-во (старый)",
    field: "material_cnt_old",
    align: "right",
  },
  {
    name: "material_cnt_new",
    label: "Кол-во (новый)",
    field: "material_cnt_new",
    align: "right",
  },
  {
    name: "unit_name",
    label: "Ед.изм",
    field: "unit_name",
    align: "left",
  },
];
export const getRowClass = (actionType, changes = false) => {
  if (changes) return "row-changed";
  switch (actionType) {
    case 1:
      return "row-red";
    case 2:
      return "row-green";
    case 3:
      return "row-orange";
    case 4:
      return "row-blue";
    default:
      return "";
  }
};
