<template>
  <div class="wrapper">
    <CustomTable title="" :rowsData="this.rowsData" :columns="this.statusHistoryTable" :custom-body="true"
      :small="true" :hide-footer-title="true" :sticky-header="false" :show-search="true" :loading="loading">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="right">
            {{ props.row.material_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.material_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.provider_request_status_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.rowversion }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <span v-html="`${props.row.fio}`"></span>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
    <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть" />
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { getStatusHistoryList } from "@/views/ProviderRequests/services";
import { statusHistoryTable } from "./services";

export default {
  name: "prov-req-status-history",
  components: { CustomTableCell, CustomTable, CustomModalFooter, },
  props: ['data', 'closeModal'],
  data() {
    return {
      statusHistoryTable, 
      rowsData: [],
      btnLoading: {},
      delBtnLoading: {},
      loading: false,
    }
  },
  methods: {
    async fetchRowsData(providerRequestId) {
      this.loading = true;
      const res = await getStatusHistoryList(providerRequestId);
      this.loading = false;
      if (!res) return;
      this.rowsData = res;
    },
  },
  mounted() {
    this.fetchRowsData(this.data.providerRequestId);
  },
}
</script>

<style scoped lang="scss">
// Настроить размер модалки 
.wrapper {
  width: 940px;
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>