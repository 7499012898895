import instance from "@/services/api";

export const remontAPI = {
  async checkIsPermissionExist(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/is_available/`).then(res => res?.data)
  },
  async getRemontHeader(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/header/read/`).then(res => res?.data)
  },
  async changeRemontGrade(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/header/grade/update/`, body).then(res => res?.data)
  },
  async addTimelineToRemont(client_request_id) {
    return await instance().post(`/client_request/${client_request_id}/header/timeline/add/`).then(res => res?.data)
  },
  async getTabs() {
    return await instance().get(`/common/tabulations/read/`).then(res => res?.data)
  },
  async getClientRequestId(remont_id) {
    return await instance().post(`/common/remont/client_request_id/get/`, {remont_id}).then(res => res?.data)
  },
  async changeIsMasterPayment(client_request_id, newValue) {
    return await instance().post(`/client_request/${client_request_id}/header/is_master_payment/`, { is_master_payment: newValue }).then(res => res?.data)
  },
}