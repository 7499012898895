<template>
  <CollapseBlock style="margin-bottom: 20px;">
    <!-- Форма для фильтров -->
    <form @submit.prevent="onSearch">
      <div class="filters">
        <!-- Поле для текста/поиск по названию материала -->
        <div class="filters__item">
          <CustomInput label="Материал" :value="values.material_name" @change="val => onChange('material_name', val)"
            @clear_input="onChange('material_name', '')" />
        </div>

        <!-- Выбор типа материала -->
        <div class="filters__item">
          <CustomSelect :options="materialTypes" label="Тип материала" optionValueKey="material_type_id"
            optionLabelKey="material_type_name" :value="values.material_type_id"
            @change="val => onChange('material_type_id', val)" />
        </div>

        <!-- Поставщик -->
        <div class="filters__item">
          <CustomSelect :options="suppliers" label="Поставщик" optionValueKey="supplier_id"
            optionLabelKey="supplier_name" :value="values.supplier_id" @change="val => onChange('supplier_id', val)" />
        </div>

        <!-- Активность материалов -->
        <div class="filters__item">
          <CustomSelect :options="materialActivity" label="Активность материалов" optionValueKey="value"
            optionLabelKey="label" :value="values.material_activity" @change="val => onChange('material_activity', val)"
            :clearable="false" />
        </div>

        <!-- Наличие -->
        <div class="filters__item">
          <CustomSelect :options="availabilityOptions" label="Наличие" optionValueKey="value" optionLabelKey="label"
            :value="values.availability" @change="val => onChange('availability', val)" :clearable="false" />
        </div>

        <!-- Город -->
        <div class="filters__item">
          <CustomSelect :options="cities" label="Город" optionValueKey="city_id" optionLabelKey="city_name"
            :value="values.city_id" @change="val => onChange('city_id', val)" />
        </div>

        <!-- Отгружаемые со склада (да/нет/все) -->
        <div class="filters__item">
          <CustomSelect :options="shippedFromWarehouse" label="Отгружаемые со склада" optionValueKey="value"
            optionLabelKey="label" :value="values.is_shipped_from_warehouse"
            @change="val => onChange('is_shipped_from_warehouse', val)" :clearable="false" />
        </div>

        <!-- Дополнительный селект материала (если нужен именно выбор по material_id) -->
        <div class="filters__item">
          <CustomSelect :options="materialsOptions" label="Материал (список)" optionValueKey="material_id"
            optionLabelKey="material_name" :value="values.material_id" @change="(val) => onChange('material_id', val)"
            :noOptionText="noOptionText" />
        </div>

        <!-- Кнопка поиска -->
        <q-btn type="submit" :loading="isFetching" size="md" no-wrap color="primary" class="q-btn" label="Поиск"
          icon="search" />
      </div>
    </form>
  </CollapseBlock>

</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomCheckbox from "@/components/form/CustomCheckbox"; // если понадобится
import CollapseBlock from "@/components/common/CollapseBlock";
import {
  getFiltersMetaData,
  shippedFromWarehouse,
  materialActivity,
  availabilityOptions,
} from "./services"; // аналог вашего API получения данных


export default {
  name: "SupplierMaterialsFilters",
  components: { CustomInput, CustomSelect, CustomCheckbox, CollapseBlock },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    noOptionText: {
      type: String,
      default: "Не найдено",
    },
    // Метод для загрузки данных (или вы можете передавать свой коллбэк)
    getData: {
      type: Function,
      default: null,
    },
    // Массив с материалами для дополнительного селекта
    materialsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      suppliers: [],
      materialTypes: [],
      cities: [],
      // Локальные справочники/опции
      shippedFromWarehouse,
      materialActivity,
      availabilityOptions,
    };
  },
  methods: {
    onChange(type, value) {
      // Вызов события наверх, чтобы родитель знал об изменении конкретного поля
      this.$emit("filter_change", type, value);
    },
    onSearch() {
      // Вызов метода из родителя для получения данных
      if (this.getData) {
        this.getData();
      }
    },
  },
  async mounted() {
    // Пример: получаем справочники для этой страницы (тип материала, поставщики, города и т.д.)
    const res = await getFiltersMetaData();
    if (!res) return;
    const [suppliersRes, materialTypesRes, citiesRes] = res;
    // Допустим, массив res содержит 3 запроса: [поставщики, типы материала, города]

    this.suppliers = suppliersRes?.data || [];
    this.materialTypes = materialTypesRes?.data || [];
    this.cities = citiesRes?.data || [];
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;

  &__item {
    flex: 1 1 220px;
  }
}

.q-btn {
  margin-top: 10px;
  align-self: flex-start;
}
</style>
