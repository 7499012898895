<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div style="margin-top: 20px;">
        <span>Дата отгрузки:</span>
        <div class="form__item">
          <CustomDate :fullWidth="true" :value="this.formData.date_shipping_plan"
            @change_date="(value) => onChange('date_shipping_plan', value)" required="true" />
        </div>
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomDate from "@/components/form/CustomDate";

export default {
  name: "prov-req-ship-from-warehouse",
  components: { CustomDate, CustomModalFooter },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        date_shipping_plan: this.data?.date_shipping_plan || '',
        provider_request_id: this.data.provider_request_id || ''
      },
      warehouseOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 300px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }
}
</style>