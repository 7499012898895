export const MaterialProviderColumns = [
  {
    name: "material_provider_id",
    label: "ID",
    field: "material_provider_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "material_id",
    label: "ID материала",
    field: "material_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "material_type_name",
    label: "Тип материала",
    field: "material_type_name",
    sortable: true,
    align: "center",
  },
  {
    name: "material_name",
    label: "Название материала",
    field: "material_name",
    sortable: true,
    align: "center",
  },
  {
    name: "price",
    label: "Цена (розничная)",
    field: "price",
    sortable: true,
    align: "center",
  },
  {
    name: "price_whole",
    label: "Цена (оптовая)",
    field: "price_whole",
    sortable: true,
    align: "center",
  },
  {
    name: "date_price",
    label: "Период действия",
    field: "date_price",
    sortable: true,
    align: "center",
  },
  {
    name: "temp_netto_price",
    label: "Цена для НеНДСника",
    field: "temp_netto_price",
    align: "center",
  },
  // Цена SR (Оптовая)
  // price_whole_sr
  {
    name: "provider_material_name",
    label: "Название материала у поставщика",
    field: "provider_material_name",
    align: "center",
  },
  {
    name: "provider_name",
    label: "Название поставщика",
    field: "provider_name",
    sortable: true,
    align: "center",
  },
  {
    name: "fulfillment_name",
    label: "Кому",
    field: "fulfillment_name",
    sortable: true,
    align: "center",
  },
  {
    name: "vendor_code",
    label: "Артикул",
    field: "vendor_code",
    sortable: true,
    align: "center",
  },
  {
    name: "is_avail_text",
    label: "Наличие",
    field: "is_avail_text",
    sortable: true,
    align: "center",
  },
  {
    name: "warehouse_name",
    label: "С собственного склада",
    field: "warehouse_name",
    align: "center",
  },
  {
    name: "is_main",
    label: "Основная цена",
    field: "is_main",
    sortable: true,
    align: "center",
  },
  {
    name: "is_top_10",
    label: "Топ 10",
    field: "is_top_10",
    sortable: true,
    align: "center",
  },
  {
    name: "is_owned_material",
    label: "Наш материал",
    field: "is_owned_material",
    sortable: true,
    align: "center",
  },
  {
    name: "photo_url_orig",
    label: "Фото",
    field: "photo_url_orig",
    sortable: true,
    align: "center",
  },
  {
    name: "dltBtn",
    label: "dlt",
    field: "dltBtn",
    sortable: true,
    align: "center",
  },
];
