export const statusHistoryTable = [
  {
    name: "material_id",
    label: "ID",
    field: "material_id",
    align: "center",
    sortable: true,
  },
  {
    name: "material_name",
    label: "Наименование",
    field: "material_name",
    align: "center",
  },
  {
    name: "provider_request_status_name",
    label: "Статус",
    field: "provider_request_status_name",
    align: "center",
  },
  {
    name: "rowversion",
    label: "Дата изменения",
    field: "rowversion",
    align: "center",
  },
  {
    name: "fio",
    label: "Исполнитель",
    field: "fio",
    align: "center",
  },
];
