<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect :options="providerOptions" label="Поставщик" :required="true" optionValueKey="provider_id"
          optionLabelKey="provider_name" :value="this.formData.provider_id"
          @change="(value) => onChange('provider_id', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getCommonProvidersData } from "@/views/ProviderRequests/services";

export default {
  name: "provider-requests-provider-form",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      provider_request_id: this.data.providerRequestId || null,
      formData: {
        provider_id: this.data?.body?.provider_id || '',
        provider_request_id_arr: this.data.body?.provider_request_id_arr || '',
        provider_request_item_id_arr: this.data.body?.provider_request_item_id_arr || '',
      },
      providerOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData, this.provider_request_id)
      this.loading = false
    },
    async fetchProviders() {
      this.providerOptions = await getCommonProvidersData();
    },
  },
  mounted() {
    this.fetchProviders();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  width: min(600px, 90vw);
  max-width: 100%;
  min-width: 300px;

  .form__item {
    flex: 1 1 45%;
  }
}
</style>