<template>
  <span v-if="isMaterialAddChange">
    <MaterialAddChange @close="closeMaterialAddChange"/> 
  </span>

  <span v-else>
    <div class="button-container">
      <q-btn @click="this.onAddMaterial" size="md" no-wrap color="primary" class="addMaterialBtn" label="Добавить материал поставщика"    icon="add" />
    </div>
      <FiltersWrapper>
      <div ref="header">
        <MaterialProviderListFilters />
        <!-- :values="this.currentFilters" :getData="this.getData" :onPrintProvReq="this.onPrintProvReq"
        @filter_change="this.onFiltersChange" :materialsOptions="this.materialsOptions"
        :noOptionText="noOptionText"  -->
      </div>
    </FiltersWrapper>

    <div>
      <CustomTable title="Список материалов поставщиков для SmartRemont" :rowsData="this.data" :columns="columns"
        :stickyHeader="false" :loading="loading" :custom-body="true" :small="true">
        <!-- :checkbox="falseIfClientRequestId" :checkbox-value="this.allSelected" :on-all-check-click="onAllCheckClick" -->
        <template #customBody="props">
          <!-- TODO checkbox -->
          <custom-table-cell align="center">
            {{ props.row.material_provider_id }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.material_id }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.material_type_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.material_name }}
            <!-- TODO ссылка ведущая на изменение материала -->
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.price }} <!-- тенге -->
            за {{ props.row.sell_unit_name }}
            <!-- TODO showHist таблица истории цены -->
            <!-- TODO МОДАЛКА: Добавить новую цену на материал -->
            <!-- Добавить новую цену на материал -->
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.price_whole }} <!-- тенге -->
            за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.date_price }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.temp_netto_price }}
            <!-- без ндс -->
            <!-- TODO вызов модалки -->
          </custom-table-cell>
          <!--             <?
        if($this->fulfillment_code == 'FULFILLMENT'){?>
        <th>Цена SR (Оптовая)</th>
        <?
        }?> ЭТО ВЕРХ ТАБЛИЦЫ, НАЗВАНИЕ КОЛОНКИ
        -->
          <!-- <? 
        if($this->fulfillment_code == 'FULFILLMENT'){?>
        <td class="bg-lightseagreen text-right"><?=tenge_text($value['price_whole_sr'])?> тг.</td>
        <?
        }?> -->
          <custom-table-cell align="center">
            {{ props.row.provider_material_name }}
            <!-- TODO изменение названия и кнопка для сохранения названия -->
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.provider_name }}
            {{ props.row.city_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <!-- style="background: <? if ($value['fulfillment_id'] == 1) echo "darkturquoise;"; elseif ($value['fulfillment_id'] == 2) echo "#d19300;"; elseif ($value['fulfillment_id'] == 3) echo "#bacd1b;"?>" -->
            {{ props.row.fulfillment_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.vendor_code }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.is_avail_text }}
            <!-- TODO: кнопка для изменения статуса, либо Сделать в наличии(+), либо Убрать из наличия(х) -->
            <!-- <?
          if($value['is_avail'] == 0){?>
          <a class="green" href="javascript:void(0);" onclick="materialProviderAvail(<?=$value['material_provider_id']?>, <?=$value['provider_id']?>, <?=$value['material_id']?>, 1)">
            <i class="ace-icon fa fa-plus bigger-130" title="Сделать в наличии"></i>
                            </a>
                            <?
                            }?>
                            <?
                            if($value['is_avail'] == 1){?>
                            <a class="red" href="javascript:void(0);" onclick="materialProviderAvail(<?=$value['material_provider_id']?>, <?=$value['provider_id']?>, <?=$value['material_id']?>, 0)">
                              <i class="ace-icon fa fa-ban bigger-130" title="Убрать из наличия"></i>
                            </a>
                            <?
                            }?> -->
          </custom-table-cell>
          <custom-table-cell align="center">
            <!-- <?
                            if($value['is_required_create'] == 1){?>
                            <img src="/img/checked-checkbox.png" alt="">
                            <?
                            }?> -->
            {{ props.row.warehouse_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <!-- <?
                            if($value['is_main'] == 1){?>
                            <img src="/img/checked-checkbox.png" alt="">
                            <?
                            }?> -->

            <!-- {{ props.row.main_price }} -->
          </custom-table-cell>
          <custom-table-cell align="center">
            <!--  <?
                        if($value['is_top_10']){?>
                            <img src="/img/checked-checkbox.png" alt="">
                            <?
                        }?> -->

            <!-- {{ props.row.top_10 }} -->
          </custom-table-cell>
          <custom-table-cell align="center">
            <!-- <?
          if($value['is_owned_material']){?>
          <img src="/img/checked-checkbox.png" alt="">
          <?
          }?> -->

            <!-- {{ props.row.our_material }} -->
          </custom-table-cell>
          <custom-table-cell>
            <!-- <img src="<?=$value['photo_url']?>"  -->
            <!-- <a href="<?=$value['photo_url_orig']?>" download>Скачать</a> -->
          </custom-table-cell>
          <custom-table-cell>
            <!-- TODO deleteBtn -->
          </custom-table-cell>
        </template>
      </CustomTable>
    </div>
  </span>
</template>

<script>
import MaterialProviderListFilters from "@/views/MaterialProviderList/MaterialProviderListFilters";
import MaterialAddChange from "@/views/MaterialProviderList/materialAddChange";

import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";

import { MaterialProviderColumns } from "./services";


export default {
  name: 'MaterialProviderList',
  components: {
    MaterialProviderListFilters,
    MaterialAddChange,
    FiltersWrapper,
    CustomTable,
    CustomTableCell,
  },
  data() {
    return {
      data: [],
      loading: false,
      // allSelected: false,
      columns: MaterialProviderColumns,
      isMaterialAddChange: false,
    }
  },
  methods: {
    closeMaterialAddChange() {
      this.isMaterialAddChange = false;
    },
    onAddMaterial() {
      this.isMaterialAddChange = true;
    },
  },
}
</script>

<style scoped>
/* Add your styles here */
.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.addMaterialBtn {
  margin-bottom: 30px;
}
</style>